import React from 'react'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Col, Nav, NavItem, NavDropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import LOL from '../../images/svg/lol-icon.svg'
import CS from '../../images/svg/cs-icon.svg'
import Dota from '../../images/svg/dota-icon.svg'
import Widget from '../../images/svg/widget-icon.svg'
import WidgetPlay from '../../images/svg/widget-play-icon.svg'

const HeaderNav = ({
  styles,
  setCsActive,
  setLolActive,
  setDotaActive,
  setMediaActive,
  tabsLoaded,
  widgetTabsLoaded,
  onLandingPage,
}) => {
  const makeTemporarilyUnclickable = ul => {
    ul.classList.add('unclickable')
    setTimeout(() => ul.classList.remove('unclickable'), 1000)
  }
  const LinkComponent =
    onLandingPage && tabsLoaded && widgetTabsLoaded ? AnchorLink : Link
  return (
    <Col lg={4} sm={6} className={styles.headerNav}>
      <Nav>
        <NavDropdown
          className={styles.navItem}
          eventKey={6}
          title='Data Widgets'
          id='products-nav-dropdown'
        >
          <li eventkey={6.1}>
            <LinkComponent
              to='/#media'
              href='#media'
              offset='100'
              onClick={e => {
                makeTemporarilyUnclickable(e.target.parentNode.parentNode)
                setMediaActive()
              }}
            >
              <Widget className={styles.dropdownIcon} />
              {'Media Widget'}
            </LinkComponent>
          </li>
          <li eventkey={6.2}>
            <Link to='/widget' href='/widget'>
              <WidgetPlay className={styles.dropdownIcon} />
              {'Live Widget Demo'}
            </Link>
          </li>
          <li eventkey={6.3}>
            <Link to='/ticker' href='/ticker'>
              <WidgetPlay className={styles.dropdownIcon} />
              {'Live Ticker Demo'}
            </Link>
          </li>
        </NavDropdown>
        <NavDropdown
          className={styles.navItem}
          eventKey={3}
          title='Analytics'
          id='products-nav-dropdown'
        >
          <li eventkey={3.1}>
            <LinkComponent
              to='/#lol'
              href='#lol'
              offset='100'
              onClick={e => {
                makeTemporarilyUnclickable(e.target.parentNode.parentNode)
                setLolActive()
              }}
            >
              <LOL className={styles.dropdownIcon} />
              {'League of Legends'}
            </LinkComponent>
          </li>
          <li eventkey={3.2}>
            <LinkComponent
              to='/#cs'
              href='#cs'
              offset='100'
              onClick={e => {
                makeTemporarilyUnclickable(e.target.parentNode.parentNode)
                setCsActive()
              }}
            >
              <CS className={styles.dropdownIcon} />
              {'Counter-Strike'}
            </LinkComponent>
          </li>
          <li eventkey={3.3}>
            <LinkComponent
              to='/#dota'
              href='#dota'
              offset='100'
              onClick={e => {
                makeTemporarilyUnclickable(e.target.parentNode.parentNode)
                setDotaActive()
              }}
            >
              <Dota className={styles.dropdownIcon} />
              {'Dota 2'}
            </LinkComponent>
          </li>
        </NavDropdown>
        {/* <NavItem className={styles.navItem} eventKey={4} href='/about'>
          About
        </NavItem> */}
        {/* <NavItem
          className={styles.navItem}
          eventKey={5}
          href='https://medium.com/@shadow_76628'
          target='_blank'
        >
          Blog
        </NavItem> */}
      </Nav>
    </Col>
  )
}

const mapStateToProps = ({ tabsLoaded, widgetTabsLoaded, onLandingPage }) => {
  return {
    tabsLoaded,
    onLandingPage,
    widgetTabsLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCsActive: () => dispatch({ type: `SET_CS_ACTIVE` }),
    setLolActive: () => dispatch({ type: `SET_LOL_ACTIVE` }),
    setDotaActive: () => dispatch({ type: `SET_DOTA_ACTIVE` }),
    setMediaActive: () => dispatch({ type: `SET_MEDIA_ACTIVE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNav)
