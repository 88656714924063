import React, { PureComponent } from 'react'
import { Navbar } from 'react-bootstrap'
import Headroom from 'react-headroom'
import HeaderBrand from './headerBrand'
import HeaderNav from './headerNav'
import HeaderLogin from './headerLogin'
import * as styles from './header.module.scss'
import Dota from '../../images/svg/dota-icon.svg'
import BgImage from '../../images/bg-dota-banner.png'
import { connect } from 'react-redux'

const HeaderDotaBanner = ({ toggleDotaPricingBanner }) => (
  <Navbar className={styles.topBar} variant='dark'>
    <Navbar.Brand className={styles.headerGameName}>
      <Dota />
      <span>DOTA 2</span>
    </Navbar.Brand>
    <Navbar.Brand className={styles.headerMessage}>
      Now Shadow Dota is available to all! Checkout the features and packages
      now. <strong>&rarr;</strong>
    </Navbar.Brand>
    <span>
      <a className={styles.subscribeButton} href={`/DOTA_pricing`}>
        <strong>Get Shadow Dota Now</strong>
      </a>
    </span>
    <span className={styles.headerClose} onClick={toggleDotaPricingBanner}>
      +
    </span>
    <div className={styles.bgImgContainer}>
      <img src={BgImage} alt='Shadow' />
    </div>
  </Navbar>
)

class Header extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showDotaPricingBanner: true,
    }
  }

  toggleDotaPricingBanner = () => {
    this.setState({ showDotaPricingBanner: false })
  }

  render() {
    let showBanner = this.state.showDotaPricingBanner
    return (
      <Headroom>
        {/*{ (showBanner) && <HeaderDotaBanner toggleDotaPricingBanner={this.toggleDotaPricingBanner} /> }*/}
        <Navbar className={styles.header} inverse collapseOnSelect>
          <HeaderBrand styles={styles} />
          <Navbar.Collapse>
            <HeaderNav styles={styles} />
            <HeaderLogin styles={styles} />
          </Navbar.Collapse>
        </Navbar>
      </Headroom>
    )
  }
}

const mapStateToProps = ({ onLandingPage }) => {
  return { onLandingPage }
}

export default connect(mapStateToProps)(Header)
