import React from 'react'
import { Col, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import LOL from '../../images/svg/lol-icon.svg'
import CS from '../../images/svg/cs-icon.svg'
import Dota from '../../images/svg/dota-icon.svg'
import HappyFace from '../../images/chat-happy.svg'
import Divider from '../../images/i-links-divider.svg'

const HeaderLogin = ({ styles }) => (
  <Col lg={4} sm={6}>
    <Nav pullRight>
      <NavItem
        className={[styles.navItem, styles.hideMobile].join(' ')}
        eventKey={4}
        onClick={() => {
          window.LeadBooster.trigger('open')
        }}
      >
        <img
          alt='get in touch'
          className={styles.dropdownIcon}
          src={HappyFace}
        />
        <span className={styles.navCta}> Get in Touch</span>
      </NavItem>
      <NavItem className={[styles.navItem, styles.hideMobile].join(' ')}>
        <img alt='divider' src={Divider} />
      </NavItem>
      <NavDropdown
        className={styles.navItem}
        eventKey={5}
        title='Sign In'
        id='signin-nav-dropdown'
      >
        <MenuItem eventKey={5.1} href='https://prd.shadow.gg/sign-in'>
          <LOL className={styles.dropdownIcon} />
          {'League of Legends'}
        </MenuItem>
        <MenuItem eventKey={5.2} href='https://cs.shadow.gg'>
          <CS className={styles.dropdownIcon} />
          {'Counter-Strike'}
        </MenuItem>
        <MenuItem eventKey={5.3} href='http://dota2.shadow.gg'>
          <Dota className={styles.dropdownIcon} />
          {'Dota 2'}
        </MenuItem>
      </NavDropdown>
    </Nav>
  </Col>
)

export default HeaderLogin
