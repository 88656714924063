// extracted by mini-css-extract-plugin
export var bannerContainer = "header-module--bannerContainer--ZvJ4M";
export var bgImgContainer = "header-module--bgImgContainer--rqqoP";
export var ctaMobile = "header-module--ctaMobile--fdx4K";
export var divider = "header-module--divider--frSmn";
export var dropdownIcon = "header-module--dropdownIcon--iga6f";
export var header = "header-module--header--4dQNe";
export var headerClose = "header-module--headerClose--s28nc";
export var headerGameName = "header-module--headerGameName--75UoP";
export var headerLogo = "header-module--headerLogo--v5jp7";
export var headerMessage = "header-module--headerMessage--ju+fR";
export var headerNav = "header-module--headerNav--6MeIF";
export var hideMobile = "header-module--hideMobile--NfEG9";
export var logoMobile = "header-module--logoMobile--lHGfE";
export var navCta = "header-module--navCta--oOtBC";
export var navItem = "header-module--navItem--Igmyq";
export var navbarBrand = "header-module--navbarBrand--Y4Jza";
export var navbarToggle = "header-module--navbar-toggle--HfxR-";
export var subscribeButton = "header-module--subscribeButton--+DOUa";
export var topBar = "header-module--topBar--CzSIT";