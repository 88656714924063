import React, { Component } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import FooterTopMask from './footerTopMask'
import FooterDetails from './footerDetails'
import * as styles from './styles.module.scss'

class Footer extends Component {
  render() {
    const {
      onLandingPage,
      tabsLoaded,
      widgetTabsLoaded,
      setLolActive,
      setCsActive,
      setDotaActive,
      setMediaActive,
    } = this.props

    const LinkComponent =
      onLandingPage && tabsLoaded && widgetTabsLoaded ? AnchorLink : Link

    // it's not pretty, but it's fine for now
    // to be refactored (if we add a CMS I suppose)
    const footerNavItems = [
      {
        title: 'Company',
        items: [
          // <Link to='/about'>About</Link>,
          <a
            href='https://medium.com/@shadow_76628'
            target='_blank'
            rel='noopener noreferrer'
          >
            Blog
          </a>,
          <a
            href='https://angel.co/dojo-madness/jobs'
            target='_blank'
            rel='noopener noreferrer'
          >
            Jobs <span className={styles.footerHiring}>We're Hiring! →</span>
          </a>,
          <a
            onClick={() => {
              window.LeadBooster.trigger('open')
            }}
          >
            Support
          </a>,
          <Link to='/privacy_policy'>Privacy Policy</Link>,
          <Link to='/terms_of_service'>Terms of Service</Link>,
          <Link to='/imprint'>Imprint</Link>,
        ],
      },
      {
        title: 'Shadow Products',
        items: [
          <LinkComponent
            to='/#lol'
            href='#lol'
            offset='100'
            onClick={setLolActive}
          >
            LOL
          </LinkComponent>,
          <LinkComponent
            to='/#cs'
            href='#cs'
            offset='100'
            onClick={setCsActive}
          >
            CS:GO
          </LinkComponent>,
          <LinkComponent
            to='/#dota'
            href='#dota'
            offset='100'
            onClick={setDotaActive}
          >
            Dota 2
          </LinkComponent>,
          <LinkComponent
            to='/#media'
            href='#media'
            offset='100'
            onClick={setMediaActive}
          >
            Media Widget
          </LinkComponent>,
        ],
      },
      {
        title: 'Social',
        items: [
          <a
            href='https://twitter.com/shadowanalytics'
            target='_blank'
            rel='noopener noreferrer'
          >
            Twitter
          </a>,
          <a
            href='https://www.facebook.com/shadowanalyticssolution/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Facebook
          </a>,
        ],
      },
    ]

    return (
      <div className={styles.footerOffset}>
        <FooterTopMask styles={styles} />
        <footer className={classnames(styles.footer, 'col-centered')}>
          <FooterDetails styles={styles} />
          {footerNavItems.map((i, index) => (
            <FooterNavBlock
              key={index}
              className={index === 1 && styles.hideOnTablet}
              title={i.title}
              items={i.items}
            />
          ))}
        </footer>
      </div>
    )
  }
}

const FooterNavBlock = ({ title, items, className }) => {
  return (
    <div className={classnames(styles.footerNav, className)}>
      <h5>{title}</h5>
      <ul>
        {items &&
          items.length &&
          items.map((i, index) => <li key={index}>{i}</li>)}
      </ul>
    </div>
  )
}

const mapStateToProps = ({ widgetTabsLoaded, tabsLoaded, onLandingPage }) => {
  return {
    tabsLoaded,
    widgetTabsLoaded,
    onLandingPage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCsActive: () => dispatch({ type: `SET_CS_ACTIVE` }),
    setLolActive: () => dispatch({ type: `SET_LOL_ACTIVE` }),
    setDotaActive: () => dispatch({ type: `SET_DOTA_ACTIVE` }),
    setMediaActive: () => dispatch({ type: `SET_MEDIA_ACTIVE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
