import React from 'react'
import logo from '../../images/shadow-logo-inverse.svg'

const FooterDetails = ({ styles }) => (
  <div className={styles.footerDetails}>
    <div className={styles.footerLogo}>
      <img src={logo} alt='shadowgg logo' />
      <p>

      </p>
    </div>
    <div className={styles.footerAddress}>
      <p>
        Shadow Esports GmbH
        <br />
        Kottbusser Straße 11
        <br />
        10999 Berlin, Germany
      </p>
    </div>
    <div className={styles.footerCompany}>
      © {new Date().getFullYear()} Shadow Esports GmbH
    </div>
  </div>
)

export default FooterDetails
