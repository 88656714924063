import React from 'react'
import { Col, Navbar } from 'react-bootstrap'
import { Link } from 'gatsby'
import logo from '../../images/shadow-logo.svg'
import HappyFace from '../../images/chat-happy.svg'

const HeaderBrand = ({ styles }) => (
  <Col lg={4} sm={12} className={styles.headerLogo}>
    <Navbar.Header className={styles.headerMobile}>
      <Navbar.Toggle className={styles.navbarToggle} />
      <Navbar.Brand className={styles.navbarBrand}>
        <Link to='/'>
          <img className={styles.logoMobile} src={logo} alt='Shadow' />
        </Link>
      </Navbar.Brand>
      <a
        style={{ float: 'right' }}
        onClick={() => {
          window.LeadBooster.trigger('open')
        }}
      >
        <img alt='get in touch' className={styles.ctaMobile} src={HappyFace} />
      </a>
    </Navbar.Header>
  </Col>
)

export default HeaderBrand
