import React from 'react'
import footerMask from '../../images/footer_mask_white_gray_line.svg'

const FooterTopMask = ({ styles }) => (
  <div
    style={{ backgroundImage: `url(${footerMask})` }}
    className={styles.footerMask}
  />
)

export default FooterTopMask
