// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--ohZ-9";
export var footerAddress = "styles-module--footerAddress--UrSfh";
export var footerCompany = "styles-module--footerCompany--gZ-U3";
export var footerDetails = "styles-module--footerDetails--BNieu";
export var footerHiring = "styles-module--footerHiring--GQ4H7";
export var footerLogo = "styles-module--footerLogo--mH3FF";
export var footerMask = "styles-module--footerMask--gcir0";
export var footerNav = "styles-module--footerNav--taMfF";
export var footerOffset = "styles-module--footerOffset--CsUWZ";
export var hideOnTablet = "styles-module--hideOnTablet--VWhhe";